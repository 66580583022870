import React from 'react'
import "./verification.css"
import { useState } from 'react'
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom'


const Verification = () => {

    const [value, setValue] = useState("+91")
    const [otpValue, setOtpValue] = useState('')
      const navigate = useNavigate()


   const submit = () => {
    console.log(otpValue,"otp value")
     navigate("/choose")

   }

  return (
    <div className='signin'>
        <div className='app-container'>
            <div className='signinwrapper'>
            <h1 className='headmain3'>Verification</h1>
            <p className='signintext'>Please Enter the code sent to your Phone</p>
           <div className='phinput'>
           <OtpInput  
                value={otpValue}
                onChange={(e) => setOtpValue(e)}
                numInputs={4}
                separator={<span>&nbsp;</span>}
                inputStyle={{width:"80%",height:"69px",borderRadius:"16px",border:"solid 0px #dbdbdb" ,backgroundColor:"#f4f4f4"}}
                isInputNum={true}
                />

          </div>


        <div className='buttondiv'>
                <button className='startorder' onClick={submit}>NEXT</button>
                </div>
            <div className='branddiv'>
                <p className='brand'>Powered by AMEPOS</p>
                </div>
            </div>
        </div>
        </div>
  )
}

export default Verification