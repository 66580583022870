import React from "react";
import "./trackorder.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { apiURI } from "../../config/config";
import Ordercard from "./ordercard";

const Trackorder = () => {
  const [tab, settab] = useState("orders");
  const [orderData, setorderData] = useState();
  const [underprepData, setunderprepData] = useState()
  const navigate = useNavigate();

  const fetchOrders = async () => {
    await fetch(apiURI.URL + "qrcode_trackorder/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        device_id: localStorage.getItem("deviceid"),
        dbname: localStorage.getItem("dbnam"),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "data");
        setorderData(data);
      })
      .catch((error) => console.error(error));
  };

  const fetchunderpreparation = async () => {
    await fetch(apiURI.URL + "qrcode_underprep/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        device_id: localStorage.getItem("deviceid"),
        dbname: localStorage.getItem("dbnam"),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "data");
        setunderprepData(data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetchOrders();
    fetchunderpreparation();
  }, [tab]);

  return (
    <div>
      <div
        style={{ textAlign: "left", padding: "5px 10px" }}
        onClick={() => navigate("/category")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-backspace-fill"
          viewBox="0 0 16 16"
        >
          <path d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241 7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2 2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147 2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0 1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976 8 5.829 5.854z" />
        </svg>{" "}
        <span className="mt-2">Back</span>
      </div>

      <div className="trackorder">
        <div className="row">
          <div className="col-12">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  background: "rgb(203 249 255)",
                  width: "auto",
                  padding: "5px 0px",
                  borderRadius: "30px",
                }}
              >
                <span
                  style={{
                    background:
                      tab === "orders" ? "#33a3f2" : "rgb(203 249 255)",
                    borderRadius: "25px",
                    padding: "5px 10px",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                  onClick={() => settab("orders")}
                >
                  Orders{" "}
                </span>
                <span
                  style={{
                    background:
                      tab === "orders" ? "rgb(203 249 255)" : "#33a3f2",
                    borderRadius: "25px",
                    padding: "5px 10px",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                  onClick={() => settab("underpreparation")}
                >
                  {" "}
                  Underpreparation
                </span>
              </div>
            </div>
          </div>
          {tab === "orders" ? (
            <div className="col-12 mt-5">
              {orderData !== undefined &&orderData?.TrackOrderArr[0]?.TrackOrder==3&&
                orderData?.TrackOrderArr.map((item) => (
                  <Ordercard item={item}></Ordercard>
                ))}
            </div>
          ) : (
            <div className="col-12 mt-5">
              {underprepData !== undefined &&underprepData?.UnderPrepArr[0]?.UnderPrep==3&&
                underprepData?.UnderPrepArr.map((item) => (
                  <Ordercard item={item}></Ordercard>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Trackorder;
