import React from 'react'
import "./signin.css"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'


const Signin = () => {
  const [value, setValue] = useState("+91")
  const navigate = useNavigate()

  const signin = () =>{
    navigate("/verification")
  }

  return (
    <div className='signin'>
      <div className='app-container'>
        <div className='signinwrapper'>
          <h1 className='headmain3'>Sign In</h1>
          <p className='signintext'>Enter your Mobile Number to signin</p>
          <div className='phinput'>
            <PhoneInput
              country={'us'}
              value={value}
              onChange={setValue}
              placeholder="Enter your 10 digit mobile number"
            />

          </div>


          <div className='buttondiv'>
            <button className='startorder' onClick={signin}>SIGN IN</button>
          </div>
          <div className='branddiv'>
            <p className='brand'>Powered by AMEPOS</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signin