import React from 'react'
import "./choosepayment.css"
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import counter from "./counter.png"
import payment from "./payment.png"

const Choosepayment = () => {
    const navigate = useNavigate()

const back = () =>{
    navigate("/cart")
}
const payAt = (pay) =>{
    console.log(pay,"pay")
    navigate("/table")
}
    

    return (
        <div className='choosepayment'>
            <div style={{ width: "80%" }}>
                <h4 className='mb-4' style={{fontWeight:"600"}}>Where would you like to checkout ?</h4>
                <div className='row mt-4'>
                    <div className='col'>
                        <div className='paymentbox' onClick={(e)=>{payAt("counter")}}>
                            <h5 className='payhead'>At Counter</h5>
                            <p  className='payp'>(Cash Only)</p>
                            <img src={counter}></img>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='paymentbox' onClick={(e)=>{payAt("online")}}>
                        <h5 className='payhead'>Online Payment</h5>
                            <p className='payp'>(Cash & Credit Only)</p>
                            <img src={payment}></img>
                        </div>
                    </div>
                </div>
            </div>
           <button style={{position:"absolute",bottom:"10px",width:"300px",border:"0px solid",backgroundColor:"#707070",color:"white",fontSize:"12px",padding:"10px",fontWeight:"700"}} onClick={back}>BACK</button>
        </div>
    )
}

export default Choosepayment