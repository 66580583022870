
 
//need to update below link
export const domain = "https://dev.api.amepos.io/"


export const apiURI = ({
    URL: 'https://dev.api.amepos.io/',

})

