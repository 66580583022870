import React from "react";
import "./order.css";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import orderno from "./orderno.png";

const Order = () => {
  const navigate = useNavigate();
  return (
    <div className="order">
      <h3 className="orderhead">
        Please Proceed to the front counter to fulfill the payment
      </h3>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div>
          <img className="odimg" src={orderno}></img>
          <p
            style={{ fontSize: "60px", marginTop: "-160px", fontWeight: "600" }}
          >
            {localStorage.getItem("tokenno")}
          </p>
        </div>
      </div>
      <button
        className="cst "
        style={{ marginTop: "100px", background: "#d7f9f8" }}
        onClick={() => {
          navigate("/trackorder");
        }}
      >
        {" "}
        Track Your Order
      </button>
    </div>
  );
};

export default Order;
