import React from 'react'
import "./choose.css"
import { useState } from 'react'
import burger from "./burger.png"
import pizza from "./pizza.png"
import dinein from "./dinein.png"
import takeaway from "./takeaway.png"

import { useNavigate } from 'react-router-dom'

const Choose = () => {
  const navigate = useNavigate()

  const dineIn = () =>
  {
    navigate("/category")
  }
  const takeAway = () =>
  {
    navigate("/category")
  }

  return (
    <div className='choose'>
   <img src={pizza} style={{float:"right"}}></img>
            <div className='choosewrapper'>
            <h1 className='headmain3 cs'>Where will you enjoy your food ?</h1>
            <div className='row mt-5'>
                <div className='col'>
                    <div className='choosebox'>
                    <img src={dinein} className="chooseimage" onClick={dineIn}></img>
                    <p className='mt-3 choosetext'>DINE IN</p>
                    </div>

                </div>
                <div className='col'>
                <div className='choosebox'>
                <img src={takeaway} className="chooseimage" onClick={takeAway}></img>
                <p className='mt-3 choosetext'>TAKEAWAY</p>

              </div>
                </div>
            </div>
       
  

  
     
            </div>
            <img src={burger} style={{position:"absolute",bottom:"0px",left:"0px"}}></img>
   
        </div>
  )
}

export default Choose