import React from "react";
import "./category.css";
import burgercoke from "./burgercoke.png";
import orderimg from "./order.png";
import logo from "./logo-apos.png";
import Cartbar from "../components/cartbar";
import Sidebar from "../components/sidebar";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { sample } from "../../reducers/ConstantSlice";
import { fetchDepartment } from "../../reducers/getDepartmentSlice";

import { itemid } from "../../reducers/ConstantSlice";
import { fetchHome } from "../../reducers/homeSlice";
import { apiURI } from "../../config/config";
const Category = () => {
  const wallet = useSelector((state) => state.constVar.sample);

  const deaprtmentData = useSelector(
    (state) => state.departmentVar.departmentList
  );

  const [category, setcategory] = useState("home");

  const [subCategoryData, setsubCategoryData] = useState("");
  const [departmentDataContent, setdepartmentDataContent] = useState();
  const [categoryDesign, setcategoryDesign] = useState();
  const [homeOffers, sethomeOffers] = useState();
  const [inventoryCategory, setinventoryCategory] = useState("");
  const [cartArray, setCartArray] = useState();
  const [orderArray, setorderArray] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customize = (plucode) => {
    console.log("plucode", plucode);

    dispatch(itemid(plucode));
    navigate("/customize");
  };

  const inventoryDetails = async (catid) => {
    setcategoryDesign(catid);
    try {
      await fetch(apiURI.URL + "qrcode_getinventory/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          device_id: localStorage.getItem("deviceid"),
          catid: catid,
          dbname: localStorage.getItem("dbnam"),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("all item data", data);
          setinventoryCategory(data);
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error, "error");
    }
  };

  const homeDetails = async () => {
    try {
      await fetch(apiURI.URL + "qrcode_getpopular/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          device_id: localStorage.getItem("deviceid"),
          dbname: localStorage.getItem("dbnam"),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("all item data", data);
          sethomeOffers(data);
          console.log("img path", data?.clientData[0]?.imgpath);
          localStorage.setItem("imgpath", data?.clientData[0]?.imgpath);
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getCart = async () => {
    try {
      await fetch(apiURI.URL + "qrcode_getcart/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          device_id: localStorage.getItem("deviceid"),
          dbname: localStorage.getItem("dbnam"),
          custid: "1",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("cartdata", data);
          setCartArray(data);
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error, "error");
    }
  };
  const getOrder = async () => {
    try {
      await fetch(apiURI.URL + "qrcode_trackorder/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          device_id: localStorage.getItem("deviceid"),
          dbname: localStorage.getItem("dbnam"),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("orderarray", data);
          setorderArray(data);
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    console.log("datalist", deaprtmentData);
    if (deaprtmentData?.department_data === undefined) {
      dispatch(fetchDepartment());
    } else {
      console.log("data");
    }
    if (homeOffers == undefined) {
      homeDetails();
    }
    getCart();
    getOrder();
  }, []);

  return (
    <div className="menu">
      <div className="menuwrapper">
        <div className="row cs">
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4 pr-1">
            <Sidebar
              category={category}
              setcategory={setcategory}
              sidebarval={deaprtmentData}
              setsubCategoryData={setsubCategoryData}
              setinventoryCategory={setinventoryCategory}
              inventoryDetails={inventoryDetails}
              homeDetails={homeDetails}
            ></Sidebar>
          </div>
          <div className="col-xl-9 col-lg-9 col-md-9 col-sm-8 col-8 p-0">
            <div className="menulist">
              {category === "home" ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <img src={burgercoke} className="bgcoke"></img>

                  <div style={{ textAlign: "left", marginTop: "20px" }}>
                    <h2
                      style={{
                        fontWeight: "700",
                        fontSize: "18px",
                        marginBottom: "2px",
                      }}
                    >
                      Good Evening
                    </h2>
                    <p
                      style={{
                        fontSize: "18px",
                        color: "#08C2C6",
                        fontWeight: "600",
                        marginBottom: "2px",
                        width: "80%",
                      }}
                    >
                      What would you like Today ?
                    </p>
                  </div>

                  {/* <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col">
                      <div className="homeitemdiv">
                        <p className="homeitemdet">Combo Meals</p>
                        <img src={burgercoke} className="homeitem"></img>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col">
                      <div className="homeitemdiv">
                        <p className="homeitemdet">Sweets and Treats</p>
                        <img src={burgercoke} className="homeitem"></img>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-1">
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col">
                      <div
                        className="homeitemdiv2"
                        style={{ backgroundColor: "yellow" }}
                      >
                        <p className="homeitemdet" style={{ color: "red" }}>
                          ALL Day Breakfast
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col">
                      <div className="homeitemdiv2">
                        <p className="homeitemdet" style={{ color: "red" }}>
                          Deals
                        </p>
                      </div>
                    </div>
                  </div> */}

                  <h5
                    className="popularitems mt-3"
                    style={{ fontSize: "16px" }}
                  >
                    Popular Items
                  </h5>
                  <div style={{ height: "48vh", overflowY: "scroll" }}>
                    <div className="row p-0">
                      {homeOffers?.salesData !== undefined &&
                        homeOffers?.salesData.map((offers) => (
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                            <div
                              className="itemdiv"
                              onClick={() => {
                                customize(offers?.plucod);
                              }}
                            >
                              <div className="mb-3 iteminner">
                                <img
                                  src={
                                    homeOffers?.clientData[0].imgpath +
                                    "Inventory/" +
                                    offers?.imagepath
                                  }
                                  className="popularimg"
                                ></img>
                              </div>
                              <p
                                className="populartext"
                                style={{
                                  fontWeight: "700",
                                  fontSize: "10px",
                                }}
                              >
                                {offers?.prodnam}
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h2 className="menuhead">{category}</h2>
                  <p className="menudet">Browse between categories below</p>

                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginBottom: "14px",
                    }}
                  >
                    {/* <button className="optionbt">All</button> */}

                    {subCategoryData?.length > 0 &&
                      subCategoryData?.map((menuoptions) => (
                        <button
                          className="optionbt"
                          style={{
                            border:
                              menuoptions?.catid == categoryDesign
                                ? "solid 1px green"
                                : "",
                          }}
                          onClick={() => {
                            inventoryDetails(menuoptions?.catid);
                          }}
                        >
                          {menuoptions?.catnam}
                        </button>
                      ))}
                  </div>
                  <div style={{ height: "65vh", overflowY: "scroll" }}>
                    <div className="row m-0">
                      {inventoryCategory?.data?.length > 0 &&
                        inventoryCategory?.data?.map((items) => (
                          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6">
                            <div
                              className="itemdiv"
                              onClick={() => {
                                customize(items?.plucod);
                              }}
                            >
                              <div className="mb-3 iteminner">
                                <img
                                  src={
                                    inventoryCategory?.imagepath_data[0]
                                      ?.imgpath +
                                    "Inventory/" +
                                    items?.imagepath
                                  }
                                  className="popularimg"
                                ></img>
                              </div>
                              <div
                                className="mb-2"
                                style={{
                                  width: "100%",
                                  height: "25px",
                                  overflowY: "scroll",
                                  textAlign: "left",
                                }}
                              >
                                <p
                                  className="populartext"
                                  style={{
                                    fontWeight: "700",
                                    fontSize: "10px",
                                  }}
                                >
                                  {items?.prodnam}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Cartbar cartcheck={cartArray}></Cartbar>
      <div
        style={{
          position: "absolute",
          top: "5px",
          left: "2px",
          background: "white",

          borderRadius: "5px",
          padding: "5px 8px",
          display: "flex",
          border: "solid 1px #cfcece",
        }}
        onClick={() => navigate("/trackorder")}
      >
        <img className="companylogo" src={logo}></img>
      </div>
      {orderArray?.TrackOrderArr[0].TrackOrder == 3 ? (
        <div
          style={{
            position: "absolute",
            top: "5px",
            right: "2px",
            background: "white",

            borderRadius: "5px",
            padding: "5px 8px",
            display: "flex",
            border: "solid 1px #cfcece",
          }}
          onClick={() => navigate("/trackorder")}
        >
          <img src={orderimg} style={{ width: "18px" }}></img>
          <p
            className="mb-0 ml-2 myod"

          >
            My Orders
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Category;
