import React, { useEffect,useState } from "react";
import burgercoke from "./burgercoke.png";

const Ordercard = ({ item }) => {
  const [trimmedurl, settrimmedurl] = useState("")
  console.log("item", item);




  useEffect(() => {
    const url = item?.imagepath;

    const parts = url.split('/');
    const lastPart = parts[parts.length - 1];
    
    console.log(lastPart);
    settrimmedurl(lastPart)
    console.log(lastPart,"trimmed url");
  }, [item?.imagepath])
  return (
    <div
      className="row mb-3"
      style={{
        padding: "20px",
        background: "#f8fbff",
        borderRadius: "2px",
      }}
    >
      <div className="col-4">
        <img
          style={{  borderRadius: "10px" }}
          src={localStorage.getItem("imgpath")+"Inventory/"+trimmedurl}
          className="ordimg"
        ></img>
      </div>
      <div className="col-6">
        <div style={{height:"auto"}}>

        
        <div style={{ textAlign: "left" }}>
          <p className="mb-1 prodname" >
            {item?.ProductName}
          </p>
          <p className="mb-1 proddet" >
            Qty : {item?.TotalQty} Nos
          </p>
          <div style={{ display: "flex" }}>
            <p className="mtt mb-1 proddet" >
              Addons:&nbsp;
            </p>
            <div style={{ display: "flex" }}>
              {item?.addon?.length > 0 &&
                item?.addon.map((addon) => (
                  <p className="mtt proddet" >
                    {addon?.addonnam},{" "}
                  </p>
                ))}
            </div>
          </div>
          <p className="mb-1 proddet" >
            Remarks :&nbsp; {item?.remarks}
          </p>
        </div>
        </div>
      </div>
      <div className="col-2">
        <div style={{ textAlign: "right" }}>
          <p className="mb-1 proddet" >
            RM
          </p>
          <p className="mb-1 proddet">
            {(item?.Amount).toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Ordercard;
