import React, { useState, useEffect } from "react";
import "./customize.css";
import { useNavigate } from "react-router-dom";
import burgercoke from "./burgercoke.png";
import shop from "./shop.png";
import close from "./cancel.png";
import { apiURI } from "../../config/config";
import { useSelector } from "react-redux";
const Customize = () => {
  const navigate = useNavigate();
  const [addpopup, setaddpopup] = useState(false);
  const plucode = useSelector((state) => state.constVar.itemid);
  const [itemDetails, setitemDetails] = useState();
  const [chooseType, setchooseType] = useState("addons");
  const [itemCount, setitemCount] = useState(1);
  const [addonList, setaddonList] = useState();
  const [stockprice, setstockprice] = useState();
  const [masteridSelect, setmasteridSelect] = useState(0);
  const [selectedAddons, setSelectedAddons] = useState({});
  const [addonTotalPrice, setaddonTotalPrice] = useState(0);
  const [remarks, setremarks] = useState("");

  const handleCheckboxChange = (masterid, addonid) => {
    setSelectedAddons((prevSelectedAddons) => {
      let updatedSelectedAddons = { ...prevSelectedAddons };

      if (prevSelectedAddons[masterid]) {
        // If the masterid exists in selectedAddons
        if (prevSelectedAddons[masterid].includes(addonid)) {
          // If addonid exists in selectedAddons[masterid], remove it
          updatedSelectedAddons[masterid] = prevSelectedAddons[masterid].filter(
            (id) => id !== addonid
          );
        } else {
          // If addonid doesn't exist in selectedAddons[masterid], add it
          updatedSelectedAddons[masterid] = [
            ...prevSelectedAddons[masterid],
            addonid,
          ];
        }
      } else {
        // If the masterid doesn't exist in selectedAddons, create it with the addonid
        updatedSelectedAddons[masterid] = [addonid];
      }
      // Calculate the total price for selected addons
      let totalPrice = 0;
      for (const selectedMasterid in updatedSelectedAddons) {
        const masterAddOn = addonList.find(
          (addon) => addon.masterid === parseInt(selectedMasterid)
        );
        if (masterAddOn) {
          updatedSelectedAddons[selectedMasterid].forEach((addonId) => {
            const selectedAddon = masterAddOn.AddonArray.find(
              (addonItem) => addonItem.addonid === parseInt(addonId)
            );
            if (selectedAddon) {
              totalPrice += selectedAddon.price;
            }
          });
        }
      }

      // Update the total price in the component state
      console.log("addon price", totalPrice);
      setaddonTotalPrice(totalPrice);
      return updatedSelectedAddons;
    });
  };

  const cancelItem = () => {
    navigate("/category");
  };
  const addtoCart = async () => {
    let formattedData = "";
    for (const masterid in selectedAddons) {
      const uniqueAddonIds = [...new Set(selectedAddons[masterid])]; // Remove duplicates
      const addonIds = uniqueAddonIds.join(",");
      if (addonIds !== "") {
        // Only add to formattedData if there are selected addons
        formattedData += `${masterid}:${addonIds}|`;
      }
    }
    // Remove the trailing "|" if it exists

    function formatDateToYYYYMMDDHHMMSS(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    var currentDate = new Date();
    let formattedDate = formatDateToYYYYMMDDHHMMSS(currentDate);

    console.log(formattedDate, "timestamp initiate");
    formattedData = formattedData.replace(/\|$/, "");
    console.log(
      "addons:",
      formattedData,
      "device id:",
      localStorage.getItem("deviceid"),
      "dbnam:",
      localStorage.getItem("dbnam"),
      "itemid:",
      plucode,
      "catid:",
      itemDetails?.catid,
      "deptid:",
      itemDetails?.deptid,
      "remarks:",
      remarks
    );

    try {
      await fetch(apiURI.URL + "qrcode_addcart/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          device_id: localStorage.getItem("deviceid"),
          dbname: localStorage.getItem("dbnam"),
          itemid: plucode,
          rateid: stockprice?.sno,
          tym: formattedDate,
          tqty: itemCount,
          remarks: remarks,
          addon: formattedData,
          custid: 1,
          catid: itemDetails?.catid,
          deptid: itemDetails?.deptid,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("response", data);
          navigate("/category");
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getItem = async () => {
    try {
      await fetch(apiURI.URL + "qrcode_getitems/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          device_id: localStorage.getItem("deviceid"),
          item_id: plucode,
          dbname: localStorage.getItem("dbnam"),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("all item data", data);
          setitemDetails(data?.Item_Details);

          let addonl = [
            {
              AddOn: 3,
              masterid: 1,
              masteraddon: "TOAST",
              minallowed: 0,
              maxallowed: 10,
              AddonArray: [
                {
                  AddOn: 3,
                  addonid: 1,
                  addonnam: "BUTTER",
                  price: 5.0,
                },
              ],
            },
            {
              AddOn: 3,
              masterid: 2,
              masteraddon: "VEG",
              minallowed: 0,
              maxallowed: 10,
              AddonArray: [
                {
                  AddOn: 3,
                  addonid: 1,
                  addonnam: "ONION",
                  price: 5.0,
                },
                {
                  AddOn: 3,
                  addonid: 2,
                  addonnam: "TOMATO",
                  price: 5.0,
                },
              ],
            },
          ];
          setaddonList(data?.Item_Details?.addons);
          // setaddonList(addonl);
          setstockprice(data?.Item_Details?.detailsArray[0]);
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getItem();
  }, []);

  return (
    <div className="customize">
      <div className="itemarea">
        <div className="row">
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-5">
            <div className="square">
              <img
                style={{ width: "100%" }}
                src={
                  localStorage.getItem("imgpath") +
                  "Inventory/" +
                  itemDetails?.imagepath
                }
              ></img>
            </div>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-8 col-sm-7 col-7">
            <div className="itemdet">
              <h2 className="itemname">{itemDetails?.prodnam}</h2>
              <p className="itemprice">
                Rs. {stockprice?.sellp}
                <span className="ml-2"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="customizearea">
        <div className="row m-0">
          <div className="col-12">
            <div className="menubarcs mb-2 ">
              <div
                className="menucatcs"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setchooseType("addons");
                }}
              >
                Addons
              </div>
              <div
                className="menucatcs"
                onClick={() => {
                  setchooseType("remarks");
                }}
              >
                Remarks
              </div>
            </div>
          </div>

          <div className="col-12">
            {chooseType === "remarks" ? (
              <div className="note mt-4">
                <p className="itemaddon">Add your request below</p>
                <input
                  type="text"
                  className="inputarea"
                  placeholder=""
                  onChange={(e) => setremarks(e.target.value)}
                ></input>
              </div>
            ) : (
              <div
                className="mt-2"
                style={{ height: "40vh", overflowY: "scroll" }}
              >
                <div className="row">
                  <div className="col-12">
                    {/* <div className="menubarcs mb-2 ">
                    {addonList?.length > 0 &&
                      addonList.map((addon, index) => (
                        
                          <div
                            className="menucatcs2"
                            style={{ marginRight: "10px",border:masteridSelect===index?"solid 1px green":"solid 1px black" }}
                            onClick={() => setmasteridSelect(index)}
                          >
                            {addon?.masteraddon}
                          </div>
                    
                      ))}
                          </div> */}
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-6 p-0">
                    <p className="itemaddon l">Addons</p>
                  </div>

                  <div className="col-6 p-0">
                    <p className="itemaddon r">Check</p>
                  </div>
                </div>
                <div>
                  {addonList != undefined &&
                    addonList.map(
                      (addon) =>
                        addon.AddonArray != undefined &&
                        addon.AddonArray.length > 0 &&
                        addon.AddonArray.map((addonItem) => (
                          <div className="row m-0" key={addonItem.addonid}>
                            <div className="col-6 p-0">
                              <p className="itemaddon name">
                                {addonItem.addonnam}
                              </p>
                            </div>
                            <div className="col-6 p-0">
                              <div
                                style={{
                                  textAlign: "right",
                                  paddingRight: "20px",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  value=""
                                  onChange={() =>
                                    handleCheckboxChange(
                                      addon.masterid,
                                      addonItem.addonid
                                    )
                                  }
                                  checked={selectedAddons[
                                    addon.masterid
                                  ]?.includes(addonItem.addonid)}
                                />
                              </div>
                            </div>
                          </div>
                        ))
                    )}

                  {addonList != undefined && addonList[0]?.AddOn == 2 ? (
                    <p>There are not addons for this item</p>
                  ) : (
                    ""
                  )}

                  {/* <div className="row m-0">
                    <div className="col-6 p-0">
                      <p className="itemaddon name">Mayonaise</p>
                    </div>
                    <div className="col-6 p-0">
                      <div style={{ textAlign: "right", paddingRight: "20px" }}>
                        <input type="checkbox" value="" />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="calculation">
        <div className="row m-0">
          <div className="col-6 ">
            <p className="itemaddon l">SubTotal</p>
          </div>

          <div className="col-6 ">
            <p className="itemaddon r">
              Rs.{itemCount * stockprice?.sellp + addonTotalPrice}
            </p>
          </div>
        </div>
      </div>
      <div className="addarea">
        <div className="addbox">
          <button
            className="btadd"
            onClick={
              itemCount <= 1
                ? () => setitemCount(1)
                : () => setitemCount(itemCount - 1)
            }
          >
            -
          </button>
          <div style={{ paddingTop: "8px" }}>
            <p style={{ fontSize: "14px", fontWeight: "700" }}>{itemCount}</p>
          </div>
          <button className="btadd" onClick={() => setitemCount(itemCount + 1)}>
            +
          </button>
        </div>
        <div className="row mt-2 pl-0 pr-0">
          <div className="col">
            <button
              className="cst"
              style={{ backgroundColor: "#707070", color: "white" }}
              onClick={cancelItem}
            >
              Cancel Item
            </button>
          </div>
          <div className="col">
            <button
              className="cst"
              style={{ backgroundColor: "#E3FF2D", color: "black" }}
              onClick={addtoCart}
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
      {addpopup ? (
        <div
          style={{
            position: "absolute",
            background: "#7d7d7d40",
            height: "100%",
            width: "100%",
            top: "0px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "300px",
              height: "330px",
              backgroundColor: "white",
              marginTop: "20%",
              padding: "30px",
            }}
          >
            <img
              src={close}
              style={{ float: "right", width: "20px" }}
              onClick={() => setaddpopup(false)}
            ></img>
            <h2 style={{ fontWeight: "600" }}>Item Added to your Bag </h2>
            <p style={{ fontWeight: "600" }}>Total has been updated</p>
            <img src={shop}></img>
            <h2 style={{ fontWeight: "700", fontSize: "50px" }}> $ 14.00</h2>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Customize;
