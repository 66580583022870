import React from "react";
import burgercoke from "./burgercoke.png";
import { useEffect,useState } from "react";

const Itemcard = ({ item, modifyCart, deleteItem }) => {
 const [trimmedurl, settrimmedurl] = useState("")
  const increaseItemCount = () => {
    console.log(item?.TotalQty + 1);
    modifyCart(
      item?.TotalQty + 1,
      item?.sno,
      item?.tokno,
      item?.PriceLevelID,
      item?.plucod,
      item?.unitsellp
    );
  };

  const decreaseItemCount = () => {
    if (item?.TotalQty - 1 < 1) {
      console.log("no function call");
    } else {
      console.log(item?.TotalQty - 1);
      modifyCart(
        item?.TotalQty - 1,
        item?.sno,
        item?.tokno,
        item?.PriceLevelID,
        item?.plucod,
        item?.unitsellp
      );
    }
  };

  const deleteqty = () => {
    console.log(item?.sno, item?.tokno, "s.no");
    deleteItem(item?.sno, item?.tokno);
  };

  useEffect(() => {
    const url = item?.imagepath;

    const parts = url.split('/');
    const lastPart = parts[parts.length - 1];
    
    console.log(lastPart);
    settrimmedurl(lastPart)
    console.log(lastPart,"trimmed url");
  }, [])
  
  console.log(item, "item in cart");

  return (
    <>
      {item?.GetCart === 3 ? (
        <div
          style={{
            border: "solid 1px #E6E6E6",
     
            height: "auto",
          }}
          className="customizeopt"
        >
         


          
          <div className="row mb-2">
            <div className=" col-4 text-left" >
              <img className="cartImg" src={localStorage.getItem("imgpath")+"Inventory/"+trimmedurl} />
            </div>
            <div className="col-5">
              <div className="pricedt">
                <p className="mb-0 cartdt">{item?.ProductName}</p>
                <p className="mb-0 cartdt">King Size</p>
              </div>
            </div>
            <div className="col-3">
              <div className="pricedt">
                <p className="mb-0 cartdtcs">Rs {item?.SellPrice}</p>
                {/* <p className="mb-0 cartdt">140 Cal</p> */}
              </div>
            </div>
          </div>
          {/* <hr style={{ borderBottom: "1px solid grey" }}></hr> */}
          <div style={{ textAlign: "left", fontSize: "12px",marginTop:"20px" }}>
            <p className="mtt mb-1">Remarks:&nbsp;{item?.remarks}</p>
            <div style={{ display: "flex",marginBottom:"10px" }}>
              <p className="mtt mb-1">Addons :&nbsp;</p>
              <div style={{ display: "flex" }}>
                {item?.addon?.length > 0 &&
                  item?.addon.map((addon) => (
                    <p className="mtt">{addon?.addonnam} &nbsp;</p>
                  ))}
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div
                  className="addbox"
                  style={{ background: "#ff4c4c" }}
                  onClick={() => deleteqty()}
                >
                  <div style={{ paddingTop: "8px" }}>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "white",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                      </svg>
                      <span style={{ marginLeft: "5px", fontSize: "12px" }}>
                        Delete Item
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="addbox">
                  <button className="btadd" onClick={() => decreaseItemCount()}>
                    -
                  </button>
                  <div style={{ paddingTop: "10px" }}>
                    <p style={{ fontSize: "14px", fontWeight: "700" }}>
                      {item?.TotalQty}
                    </p>
                  </div>
                  <button className="btadd" onClick={() => increaseItemCount()}>
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Itemcard;
