import { createSlice, createEntityAdapter, createAsyncThunk} from '@reduxjs/toolkit';
import { apiURI } from '../config/config';
const userAdapter = createEntityAdapter();
const initialState = userAdapter.getInitialState({
    departmentList:[],
    loading: false,
    error: null,
});

// Define the asynchronous thunk for fetching posts
export const fetchDepartment = createAsyncThunk('posts/fetchPosts', async () => {
    const response = 
    await fetch(apiURI.URL+"qrcode_getdepartment/", {
        method: 'POST',
        headers: {
           'Content-Type': 'application/json',
        },
        body: JSON.stringify(
           {
             "device_id":localStorage.getItem("deviceid"),
             "dbname": localStorage.getItem("dbnam")
           }
        )
     })
    if (!response.ok) {
      throw new Error('Failed to fetch posts.');
    }
    const data = await response.json();
    return data;
  });


export const departmentSlice = createSlice({
    name: 'departmentdata',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
          .addCase(fetchDepartment.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchDepartment.fulfilled, (state, action) => {
            state.loading = false;
            state.departmentList = action.payload;
            state.error = null;
          })
          .addCase(fetchDepartment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
          });
      },
});

export const {departmentList} = departmentSlice.actions;
export default departmentSlice.reducer;






















