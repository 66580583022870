import React from 'react'
import "./table.css"
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'


const Table = () => {
    const navigate = useNavigate()

    const back = () => {
        navigate("/choosepayment")
    }
    const proceedPay = () => {
        console.log("Proceed to counter or online payment")
        navigate("/order")
    }



    return (
        <div className='table'>
            <div style={{ padding: "0px 35px 0px 35px" }}>
                <h4 className='mb-4' style={{ fontWeight: "600" }}>Grab a Table tent and enter the code</h4>
                <input type="text" className='inputtable'></input>
            </div>
            <div style={{ position: "absolute", width: "100%", bottom: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className="m-1" style={{ width: "300px", border: "0px solid", backgroundColor: "#707070", color: "white", fontSize: "12px", padding: "10px", fontWeight: "600" }} onClick={back}>Back</button>
                    <button className="m-1" style={{ width: "300px", border: "0px solid", backgroundColor: "#E3FF2D", color: "black", fontSize: "12px", padding: "10px", fontWeight: "600" }} onClick={proceedPay}>Continue</button>
                </div>
            </div>
        </div>
    )
}

export default Table