import React from "react";
import { useNavigate } from "react-router-dom";
import { apiURI } from "../../config/config";
const Sidebar = ({ setcategory, sidebarval, setsubCategoryData,inventoryDetails,homeDetails }) => {
  const navigate = useNavigate();


  const navigateCategory = async (cat, dept_id) => {

    setcategory(cat);
 if(cat==="home"){
  homeDetails();
 }
 else{
  if (dept_id === undefined || dept_id === "") {
    console.log("id not defined");
  } else {
    try {
      await fetch(apiURI.URL + "qrcode_getcategory/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          device_id: localStorage.getItem("deviceid"),
          deptid: dept_id,
          dbname: localStorage.getItem("dbnam"),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("all item data", data);
          setsubCategoryData(data);

          inventoryDetails(data[0]?.catid)

        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error, "error");
    }
  }

 }

  };

  





  console.log("sidebar categories", sidebarval?.department_data);

  return (
    // <div className="fixedbar" >
    <div className="menubar">
      


      
      <div
        className="menucat"
        onClick={(e) => {
          navigateCategory("home");
        }}
      >
        Home
      </div>

      {sidebarval?.department_data !== undefined &&
        sidebarval?.department_data.map((menuoptions,index) => (
          <div
            className="menucat"
            onClick={(e) => {
              navigateCategory(menuoptions?.deptnam, menuoptions?.deptid);
            }}
          >
            {menuoptions?.deptnam}
          </div>
        ))}

      {/* <div className='menucat' onClick={(e)=>{navigateCategory("Meals")}}>
           Meals
           </div>
           <div className='menucat' onClick={(e)=>{navigateCategory("Cafe")}}>
           Cafe
           </div>
           <div className='menucat' onClick={(e)=>{navigateCategory("Fries")}}>
           Fries
           </div>
           <div className='menucat' onClick={(e)=>{navigateCategory("Burgers")}}>
           Burgers
           </div>
           <div className='menucat' onClick={(e)=>{navigateCategory("Pizzas")}}>
           Pizzas
           </div>
           <div className='menucat' onClick={(e)=>{navigateCategory("Salads")}}>
           Salads
           </div>
           <div className='menucat' onClick={(e)=>{navigateCategory("Happymeals")}}>
           Happy Meals
           </div>
           <div className='menucat' onClick={(e)=>{navigateCategory("Sweets")}}>
           Sweets
           </div> */}
    </div>
    // </div>
  );
};

export default Sidebar;
