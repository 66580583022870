
import './App.css';
import Home from "./Pages/Home/home"
import Signing from "./Pages/signin/signin"
import 'bootstrap/dist/css/bootstrap.min.css';
import Verification from './Pages/verification/verification';
import Choose from './Pages/choose/choose';

import Category from './Pages/category/category';
import Customize from './Pages/customize/customize';
import Cart from "./Pages/cart/cart"
// import Track from "./Pages/track/track"

import {  Routes, Route ,Redirect, Navigate} from 'react-router-dom';
import Choosepayment from './Pages/choosepayment/choosepayment';
import Table from './Pages/table/table';
import Order from './Pages/order/order';
import Trackorder from './Pages/track/trackorder';

function App() {
  return (
    
  
    <div className="App">
    <Routes>
    <Route exact path="/" element={<Home/>} />
    <Route path="/signin" element={<Signing/>} />
    <Route path="/home" element={<Home/>} />
    <Route path="/verification" element={<Verification/>} />
    <Route path="/choose" element={<Choose/>} />
    {/* <Route path="/track" element={<Track/>} /> */}
    <Route path="/trackorder" element={<Trackorder/>} />
    <Route path="/category" element={<Category/>} />
    <Route path="/customize" element={<Customize/>} />
    <Route path="/cart" element={<Cart/>} />
    <Route path="/choosepayment" element={<Choosepayment/>} />
    <Route path="/table" element={<Table/>} />
    <Route path="/order" element={<Order/>} />


    </Routes>
    </div>
    
  );
}

export default App;
