import { configureStore } from '@reduxjs/toolkit'
import ConstantSlice from '../reducers/ConstantSlice'
import departmentList from '../reducers/getDepartmentSlice'
import homeList  from '../reducers/homeSlice'



export default configureStore({
  reducer: {
    constVar:ConstantSlice,
    departmentVar:departmentList,
    homeVar:homeList,

},
})