import React from "react";
import "./cart.css";
import { useNavigate } from "react-router-dom";
import shop from "./shop.png";
import burgercoke from "./burgercoke.png";
import { useSelector } from "react-redux";
import { apiURI } from "../../config/config";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Itemcard from "./itemCard";

const Customize = () => {
  const navigate = useNavigate();
  const plucode = useSelector((state) => state.constVar.itemid);
  const [cartItems, setcartItems] = useState();
  const [Tables, setTables] = useState();
  const [selectedTable, setselectedTable] = useState();
  const [subtotal, setsubtotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);

  const orderMore = () => {
    navigate("/category");
  };

  const completeOrder = async () => {
    console.log(
      "details",
      selectedTable,
      localStorage.getItem("dbnam"),
      localStorage.getItem("deviceid")
    );
    // navigate("/choosepayment");

    if (selectedTable !== undefined) {
      await fetch(apiURI.URL + "qrcode_placeorder/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          device_id: localStorage.getItem("deviceid"),
          dbname: localStorage.getItem("dbnam"),
          table: selectedTable,
          tokno: cartItems[0]?.tokno,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("placeorder", data);
          if (data?.PlaceOrderArr[0]?.PlaceOrder === 2) {
            alert("error placing order !!");
          } else if (data?.PlaceOrderArr[0]?.PlaceOrder === 3) {
            alert("order placed successfully!!");
            navigate("/order");
            localStorage.setItem("tokenno",cartItems[0]?.tokno)
          } else {
            alert("error placing order !!");
          }
        })
        .catch((error) => console.error(error));
    } else {
      alert("please select table no ");
    }
  };

  const modifyCart = async (itemcount, sno, tokno, rateid, plucod, usp) => {
    await fetch(apiURI.URL + "qrcode_modifycartitem/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        device_id: localStorage.getItem("deviceid"),
        dbname: localStorage.getItem("dbnam"),
        sno: sno,
        tokno: tokno,
        tqty: itemcount,
        rateid: rateid,
        plucod: plucod,
        usellp: usp,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("modify cart", data);
        getCart();
      })
      .catch((error) => console.error(error));

    console.log(itemcount, "itemcount in modifyCart");
  };

  const deleteItem = async (sno, tokno) => {
    await fetch(apiURI.URL + "qrcode_deletecart/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        device_id: localStorage.getItem("deviceid"),
        dbname: localStorage.getItem("dbnam"),
        sno: sno,
        tokno: tokno,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("delete item", data);
        getCart();
      })
      .catch((error) => console.error(error));
  };

  const getCart = async () => {
    await fetch(apiURI.URL + "qrcode_getcart/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        device_id: localStorage.getItem("deviceid"),
        dbname: localStorage.getItem("dbnam"),
        custid: "1",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("all item data", data);
        setcartItems(data?.GetCartArr);
        let subtotal = data?.GetCartArr.reduce(
          (acc, item) => acc + item.SellPrice,
          0
        );
        let tax = data?.GetCartArr.reduce((acc, item) => acc + item.GST, 0);
        let total = data?.GetCartArr.reduce(
          (acc, item) => acc + item.Amount,
          0
        );
        console.log(subtotal, tax, total, "after get");
        setsubtotal(subtotal);
        setTax(tax);
        setTotal(total);
      })
      .catch((error) => console.error(error));
  };

  const getTable = async () => {
    await fetch(apiURI.URL + "qrcode_tabledetails/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        device_id: localStorage.getItem("deviceid"),
        dbname: localStorage.getItem("dbnam"),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("all table data", data);
        setTables(data?.TableDetailsArr);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getCart();
    getTable();
  }, []);

  return (
    <div className="cart">
      <div>
        <div className="row">
          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
            <img src={shop} />
          </div>
          <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
            <div style={{ textAlign: "left" }}>
              <h4 className="mb-0 mt-1 ct">Cart</h4>
              <p className="ctp">Review your Items</p>
            </div>
          </div>
        </div>

        <div style={{ height: "60vh", overflowY: "auto" }}>
          {cartItems?.length > 0 &&
            cartItems.map((item) => (
              <Itemcard
                item={item}
                modifyCart={modifyCart}
                deleteItem={deleteItem}
              ></Itemcard>
            ))}
        </div>
        <div style={{height:"10vh"}}>
        <div className="row mt-2 p-2">
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => {
              setselectedTable(e.target.value);
            }}
          >
            <option>Select Table</option>
            {Tables?.length > 0 &&
              Tables.map((tab) => (
                <option value={tab?.tabno}>{tab?.tabno}</option>
              ))}
          </Form.Select>
        </div>


        </div>
 
      </div>

      <div className="bill" style={{height:"20vh"}}>
        <div className="row">
          <div className="col">
            <div style={{ textAlign: "left" }}>
              <h6>Sub Total</h6>
              <h6>Tax</h6>
              <h6>Total</h6>
              <button
                className="cst"
                style={{ backgroundColor: "#E3FF2D", color: "black" }}
                onClick={orderMore}
              >
                Order More
              </button>
            </div>
          </div>
          <div className="col">
            <div style={{ textAlign: "right" }}>
              <h6>Rs. {subtotal}</h6>
              <h6>Rs. {tax}</h6>
              <h6>Rs. {total}</h6>
              <button
                className="cst"
                style={{ backgroundColor: "#0EF170", color: "white" }}
                onClick={completeOrder}
              >
                Complete Order
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customize;
