import { createSlice, createEntityAdapter} from '@reduxjs/toolkit';
const userAdapter = createEntityAdapter();
const initialState = userAdapter.getInitialState({
    sample:'product',
    itemid:''
});
export const constantSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {
        sample(state, action) {
            state.sample = action.payload;
            console.log(action.payload, "datadata");
        },
        itemid(state, action) {
            state.itemid = action.payload;
            console.log(action.payload, "itemid");
        },

    },
});

export const {sample,itemid} = constantSlice.actions;

export default constantSlice.reducer;






















