import React from 'react'
import "./home.css"
import homeicon from "./homeicon.png"
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const Home = () => {
  const navigate = useNavigate()
  const tosignin = () =>{

  


    function generateDeviceId() {
      // Generate a random alphanumeric string
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let deviceId = '';
      const length = 10; // Length of the device ID
    
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        deviceId += characters[randomIndex];
      }
    
      return deviceId;
    }
    const deviceId = generateDeviceId();
    localStorage.setItem("deviceid", deviceId)
    navigate("/category")
  }

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const dbnam = urlParams.get('dbnam')
    localStorage.setItem("dbnam",dbnam);
  }, [])
  

  
  return (
    <div className='home'>
        <div className='app-container'>
            <div className='homewrapper'>
            <h2 className='headmain1'>FAST & EASY</h2>
            <h1 className='headmain2'>Order & Pay Here</h1>
                <img className="homeicon" src={homeicon}></img>

                <div className='buttondiv'>
                <button className='startorder' onClick={tosignin}>START YOUR ORDER</button>
                </div>
                <div className='branddiv'>
                <p className='brand'>Powered by AMEPOS</p>
                </div>
                
            </div>
        </div>

        </div>
  )
}

export default Home